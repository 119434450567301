export const celebrate = () => {
  fire(0.25,  {spread: 26, startVelocity: 55})
  fire(0.2,   {spread: 60})
  fire(0.35,  {spread: 100, decay: 0.91})
  fire(0.1,   {spread: 120, startVelocity: 25, decay: 0.92})
  fire(0.1,   {spread: 120, startVelocity: 45})
  fire(0.5,   {spread: 170, startVelocity: 30})
  fire(0.7,   {spread: 200, startVelocity: 55})
}

export const fireworks = () => {
  var duration = 1 * 1000
  var animationEnd = Date.now() + duration
  var defaults = {startVelocity: 30, spread: 250, ticks: 170, zIndex: 0}
  var interval = setInterval(function() {
    var timeLeft = animationEnd - Date.now()
    if(timeLeft <= 0) { return clearInterval(interval) }
    var particleCount = 50 * (timeLeft / duration)
    window.confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }))
    window.confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }))
    window.confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.3, 0.7), y: Math.random() - 0.2 } }))
  }, 250)
}

export const fire = (particleRatio, opts) => {
  var count = 200
  var defaults = {origin: { y: 0.7 }, useWorker: true}
  window.confetti(Object.assign({}, defaults, opts, {
    particleCount: Math.floor(count * particleRatio)
  }))
}

export const randomInRange = (min, max) => {
  return Math.random() * (max - min) + min;
}