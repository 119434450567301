import React from "react"

export const Preview = ({ meta }) => {
  const { name, percent, status } = meta
  var val = Math.round(percent)
  var st = {width: `${val}%`}
  return (
    <div className="uploader-progress">
      <h5>{name}</h5>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style={st}
          aria-valuenow={val}
          aria-valuemin="0"
          aria-valuemax="100"
        >{val}%</div>
      </div>
      <div className="progress-status">
        Status: {status}
      </div>
    </div>
  )
}
